import { Inject, Injectable, InjectionToken } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';

export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window,
});

@Injectable({
  providedIn: 'root',
})
/**
 * Wraps the global window object and providers helper functions for common browser operations.
 *
 * The goals of this class are to:
 *   - Centralize, test once and reuse logic for common browser operations
 *   - Support simpler testing of code that depends on global window object
 */
export class BrowserService {
  private logger: ILogger;

  public get host(): string {
    return this.window.location.host;
  }

  public get pathname(): string {
    return this.window.location.pathname;
  }

  public get protocol(): string {
    return this.window.location.protocol;
  }

  constructor(
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory,
    @Inject(WINDOW) private window: Window
  ) {
    this.logger = loggerFactory('BrowserService');
  }

  public async fetchJSON<T>(
    url: string,
    method: string,
    body: string
  ): Promise<T> {
    let response;
    try {
      response = await this.window.fetch(url, {
        method: method,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: body,
      });

      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        throw new Error('Failed to fetch');
      }

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError(
          `Expected content-type=application/json, received ${contentType}.`
        );
      }
      return await response.json();
    } catch (error: unknown) {
      this.logger.warn('Fetch Failed', {
        url: url,
        method: method,
        status: response?.status,
      });
      throw error;
    }
  }

  /**
   * Application triggered reload of the current page.
   *
   * @returns {Promise<boolean>} - This method will only ever return if a browser operation takes longer than 30
   * seconds, and will return a rejected promise. The caller must handle any rejections which would be
   * exceptional.
   */
  public async reload(reason: string): Promise<boolean> {
    this.logger.info('Reloaded', { reason: reason });

    this.window.location.reload();

    return await this.waitForBrowser();
  }

  /**
   * Application triggered redirect to a given URL.
   *
   * @returns {Promise<boolean>} - This method will only ever return if a browser operation takes longer than 30
   * seconds, and will return a rejected promise. The caller must handle any rejections which would be
   * exceptional.
   */
  public async redirect(url: string | URL, reason: string): Promise<boolean> {
    this.logger.info('Redirected', {
      reason: reason,
      origin: this.window.location.origin,
      target: url,
    });

    this.window.location.assign(url);

    return await this.waitForBrowser();
  }

  /**
   * Application triggered redirect to the MyDocBill global site URL.
   *
   * @returns {Promise<boolean>} - This method will only ever return if a browser operation takes longer than 30
   * seconds, and will return a rejected promise. The caller must handle any rejections which would be
   * exceptional.
   */
  public async toGlobalSite(reason: string): Promise<boolean> {
    return await this.redirect(
      `${this.protocol}//${environment.webAppHost}`,
      reason
    );
  }

  /**
   * Block for 30s while waiting for the browser to process an operation.
   *
   * @returns {Promise<boolean>} - This method will only ever return if a browser operation takes longer than 30
   * seconds, and will return a rejected promise. The caller must handle any rejections which would be
   * exceptional.
   */
  private waitForBrowser(): Promise<boolean> {
    return new Promise((_resolve, reject) => {
      setTimeout(() => {
        this.logger.warn('Browser Operation Timed Out');

        return reject(new Error('Browser Operation Timed Out'));
      }, 30000);
    });
  }
}
