/**
 * Enum for the types of URLs that can be configured as a virtual site within the application.
 * @readonly
 * @enum {string}
 */
export enum VirtualSiteType {
  /** @member {string}
   * e.g. mydocbill.com
   */
  Global = 'Global',

  /** @member {string}
   * e.g. client.mydocbill.com
   *      client.mydocbill.com/bg1
   *      client.mydocbill.com/bg2
   */
  Subdomain = 'Subdomain',

  /**
   * @member {string}
   * e.g. mydocbill.com/clientbg1
   *      mydocbill.com/clientbg2
   */
  Virtual = 'Virtual',

  /** @member {string}
   * An unmatched URL
   */
  Unknown = 'Unknown',
}

export class VirtualSite {
  /** @member {string}
   * The root path of the SPA to be provided as the value of the Angular
   * [APP_BASE_HREF](https://angular.io/api/common/APP_BASE_HREF) DI Token.
   */
  public get appBaseHref(): string {
    if (this.type === VirtualSiteType.Unknown) {
      throw new Error('Not a recognized site.');
    }

    if (this.type === VirtualSiteType.Virtual) {
      return `/${this.url.split('/')[1]}`;
    }
    return '/';
  }

  /** @member {string}
   * e.g. The URL of a matched virtual site within the application.
   */
  public get url(): string {
    if (this.type === VirtualSiteType.Unknown) {
      throw new Error('Not a recognized site.');
    }

    return this.matchedUrl;
  }

  constructor(
    private matchedUrl: string,
    public type: VirtualSiteType
  ) {}
}
