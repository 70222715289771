import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import { VirtualSiteService } from '../virtual-site/virtual-site.service';

export interface featureToggles {
  name: string;
  enabled: boolean;
}

/**
 * Schema of the API POST /ClientSettings response
 *
 * @interface clientSettingsResponse
 * @siteUrl URL for the virtual site settings represented.
 */
export interface clientSettingsResponse {
  siteUrl: string;
  supportsBillingGroups: boolean;
  clientId: string;
  billingGroupId: string;
  termsOfUseUrl: string;
  privacyPolicyUrl: string;
  faqUrl: string;
  companyNameDisplay: string;
  companyWebsiteUrl: string;
  companyPhone: string;
  primaryColor: string;
  siteDescription: string;
  billingClientType: number;
  rollUpEncounterInsurancePaymentsOnStatement: boolean;
  feedbackPromptAfterPayment: boolean;
  feedbackUrl: string;
  feedbackPromptText: string;
  feedbackPromptButtonText: string;
  acceptCreditCards: boolean;
  acceptElectronicChecks: boolean;
  acceptPaymentPlans: boolean;
  acceptVisa: boolean;
  acceptMasterCard: boolean;
  acceptDiscover: boolean;
  acceptAmericanExpress: boolean;
  acceptApplePay: boolean;
  minimumPaymentAmount: number;
  maximumPaymentPlanDuration: number;
  offerEmail: boolean;
  offerTextMessage: boolean;
  enableAccountLookup: boolean;
  treatAsGlobal: boolean;
  featureToggles: featureToggles[];
  logoGraphic: string;
  bannerGraphic: string;
  payOnlyPhone: string;
  usePaymentRounding: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ClientSettingsService {
  endPointBase: string = '/ClientSettings';

  response: clientSettingsResponse = {
    siteUrl: '',
    supportsBillingGroups: true,
    clientId: '',
    billingGroupId: '0',
    termsOfUseUrl: 'https://dcsokk5etzbbl.cloudfront.net/Pxp/terms-of-use.html',
    privacyPolicyUrl:
      'https://dcsokk5etzbbl.cloudfront.net/Pxp/privacy-policy.html',
    faqUrl: '',
    logoGraphic: '/assets/images/logo_transparent.png',
    bannerGraphic: '/assets/images/green_header.jpg',
    companyNameDisplay: '',
    companyWebsiteUrl: '',
    primaryColor: '',
    siteDescription: '',
    billingClientType: 2,
    enableAccountLookup: true,
    offerEmail: true,
    offerTextMessage: true,
    rollUpEncounterInsurancePaymentsOnStatement: true,
    featureToggles: [],
    feedbackPromptAfterPayment: true,
    feedbackUrl: '',
    feedbackPromptText: '',
    feedbackPromptButtonText: '',
    companyPhone: '',
    acceptCreditCards: true,
    acceptElectronicChecks: true,
    acceptPaymentPlans: true,
    acceptVisa: true,
    acceptMasterCard: true,
    acceptDiscover: true,
    acceptAmericanExpress: true,
    acceptApplePay: true,
    minimumPaymentAmount: 0,
    treatAsGlobal: false,
    maximumPaymentPlanDuration: 0,
    payOnlyPhone: '',
    usePaymentRounding: true,
  };

  public get complete(): boolean {
    return this._complete;
  }

  public set complete(value: boolean) {
    this.logger.debug('Property Changed', {
      name: 'complete',
      old: this._complete,
      new: value,
    });

    this._complete = value;
  }

  private _complete: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseSubject = new BehaviorSubject<clientSettingsResponse | any>(null);
  response$ = this.responseSubject.asObservable();

  private logger: ILogger;
  private virtualSiteService: VirtualSiteService = inject(VirtualSiteService);

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('ClientSettingsService');

    this.logger.debug('Initialized');
  }

  fetchClientSettings() {
    this.post().subscribe({
      next: (response: HttpResponse<clientSettingsResponse>) => {
        const _ = this.processResponse(response);
      },
    });
  }

  async fetchClientSettingsAsync(): Promise<clientSettingsResponse> {
    const response = await firstValueFrom(this.post());

    return this.processResponse(response);
  }

  getClientSettings() {
    // Return data stored in the service
    return this.response;
  }

  private post(): Observable<HttpResponse<clientSettingsResponse>> {
    return this.http.post<clientSettingsResponse>(
      environment.apiUrl + this.endPointBase,
      { siteUrl: this.virtualSiteService.site.url },
      {
        observe: 'response',
      }
    );
  }

  private processResponse(response: HttpResponse<clientSettingsResponse>) {
    let clientSettingsResponse: clientSettingsResponse;

    if (response.status === 200 && response.body) {
      clientSettingsResponse = response.body as clientSettingsResponse;
      this.response = clientSettingsResponse;

      this.complete = true;

      this.responseSubject.next(clientSettingsResponse);
    }

    return clientSettingsResponse!;
  }
}
