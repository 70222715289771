import { APP_BASE_HREF } from '@angular/common';
import {
  EnvironmentProviders,
  Inject,
  Injectable,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { isNullEmptyOrWhitespace } from '../../helpers/string';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import { BrowserService } from '../browser/browser.service';
import { clientSettingsResponse } from '../client-settings/client-settings.service';

import { VirtualSite, VirtualSiteType } from './virtual-site';

export const MDB_APP_BASE_HREF = new InjectionToken<Record<string, string>>(
  'MDB_APP_BASE_HREF'
);

/**
 * Provides the value for the Angular [APP_BASE_HREF](https://angular.io/api/common/APP_BASE_HREF) DI Token. The value
 * is consumed by the Angular Router when configured with PathLocationStrategy. The value is the used as the root of
 * the application when generating and matching URLs in the Router.
 *
 * The value is dynamically configured by inspecting the URL the application is launched from and comparing the URL to
 * to URLs configured in EBCEnterprise.dbo.PatientExperienceSettings.
 * @usageNotes
 *
 * This provider builder is inteneded to be called before Angular bootstrap. This is to work around the Angular
 * limitation that does not allow asynchronous providers for dynamic configuration.
 * See https://github.com/angular/angular/issues/23279
 */
export async function provideAppBaseHref(
  loggerFactory: LoggerFactory,
  browserService: BrowserService
): Promise<EnvironmentProviders> {
  const service = VirtualSiteService.factory(loggerFactory, browserService);

  if (await service.matchOrRedirect()) {
    return makeEnvironmentProviders([
      {
        provide: APP_BASE_HREF,
        useValue: service.site?.appBaseHref,
      },
    ]);
  }

  throw new Error('Failed to Provide APP_BASE_HREF');
}

@Injectable({
  providedIn: 'root',
})
export class VirtualSiteService {
  private readonly logger: ILogger;
  private readonly clientSettingsUrl: string = `${environment.apiUrl}/ClientSettings`;

  private initialized = false;
  public site: VirtualSite = new VirtualSite('', VirtualSiteType.Unknown);

  private constructor(
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory,
    private browserService: BrowserService
  ) {
    this.logger = loggerFactory('VirtualSiteService');
  }

  public static factory(
    loggerFactory: LoggerFactory,
    browserService: BrowserService
  ): VirtualSiteService {
    if (VirtualSiteService.instance) return VirtualSiteService.instance;

    const instance = new VirtualSiteService(loggerFactory, browserService);

    VirtualSiteService.instance = instance;

    return VirtualSiteService.instance;
  }

  public async matchOrRedirect(): Promise<boolean> {
    if (this.initialized) {
      throw new Error('matchOrRedirect should only be called once');
    }

    const match = await this.matchSite();

    this.logger.debug('Site Match Result', {
      type: match.type,
      url: match.type === VirtualSiteType.Unknown ? '' : match.url,
    });

    if (match.type === VirtualSiteType.Unknown) {
      return await this.browserService.toGlobalSite('Unknown Virtual Site');
    }

    this.site = match;
    this.initialized = true;
    return true;
  }

  public isSameSite(otherSiteUrl: string): boolean {
    return otherSiteUrl.toLowerCase() === this.site.url.toLowerCase();
  }

  private static instance?: VirtualSiteService;

  private async matchSite(): Promise<VirtualSite> {
    return (
      (await this.matchVirtualDirectory()) ??
      (await this.matchSubdomain()) ??
      this.matchGlobal() ??
      new VirtualSite('', VirtualSiteType.Unknown)
    );
  }

  private async matchVirtualDirectory(): Promise<VirtualSite | null> {
    function parseUrl(this: VirtualSiteService): string | null {
      const urlPath = this.browserService.pathname.split('/');

      if (urlPath.length <= 1 || isNullEmptyOrWhitespace(urlPath[1])) {
        return null;
      }

      return `${this.browserService.host}/${urlPath[1]}`;
    }

    const url = parseUrl.call(this);

    if (!url) {
      this.logger.debug('Virtual Directory Match Skipped', {
        host: this.browserService.host,
        path: this.browserService.pathname,
      });

      return null;
    }

    const matched = await this.siteExists(url);

    this.logger.debug('Virtual Directory Match Attempted', {
      host: this.browserService.host,
      path: this.browserService.pathname,
      matched: matched,
    });

    if (matched) {
      return new VirtualSite(url, VirtualSiteType.Virtual);
    }

    return null;
  }

  private async matchSubdomain(): Promise<VirtualSite | null> {
    function parse(this: VirtualSiteService): string | null {
      if (
        this.browserService.host
          .toLowerCase()
          .startsWith(environment.webAppHost.toLowerCase())
      ) {
        return null;
      }

      return this.browserService.host;
    }

    const url = parse.call(this);

    if (!url) {
      this.logger.debug('Subdomain Match Skipped', {
        host: this.browserService.host,
      });

      return null;
    }

    const matched = await this.siteExists(url);

    this.logger.debug('Subdomain Match Attempted', {
      host: this.browserService.host,
      path: this.browserService.pathname,
      matched: matched,
    });

    if (matched) {
      return new VirtualSite(url, VirtualSiteType.Subdomain);
    }

    return null;
  }

  private matchGlobal(): VirtualSite | null {
    const matched =
      this.browserService.host.toLowerCase() ===
      environment.webAppHost.toLowerCase();

    this.logger.debug('Global Site Match Attempted', {
      host: this.browserService.host,
      path: this.browserService.pathname,
      matched: matched,
    });

    if (matched) {
      return new VirtualSite(this.browserService.host, VirtualSiteType.Global);
    }

    return null;
  }

  private async siteExists(url: string): Promise<boolean> {
    const settings =
      await this.browserService.fetchJSON<clientSettingsResponse>(
        this.clientSettingsUrl,
        'POST',
        JSON.stringify({ siteUrl: url.toLowerCase() })
      );

    if (settings.siteUrl.toLowerCase() !== url.toLowerCase()) {
      return false;
    }

    if (isNullEmptyOrWhitespace(settings.clientId)) {
      return false;
    }

    return true;
  }
}
